import React, {
  useState,
  useEffect,
  forwardRef,
  Fragment,
  useRef,
} from "react";
import {
  CssBaseline,
  Grid,
  createMuiTheme,
  ThemeProvider,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  AddBox,
  Check,
  Clear,
  DeleteOutline,
  Delete,
  Edit,
  SaveAlt,
  FilterList,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  ArrowDownward,
  Remove,
  ViewColumn,
} from "@mui/icons-material";
import axios from "axios";
import errorHandle from "./error_handleing.js";
import MaterialTable from "material-table";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useIdleTimer } from "react-idle-timer";
import { BASE_URL } from "./config"; // Import the BASE_URL variable

window.onerror = function (msg, url, lineNo, columnNo, error) {
  errorHandle(msg, lineNo, columnNo, error);
  alert(
    "msg: ",
    msg,
    "\nlineNo: ",
    lineNo,
    "\ncolumnNo: ",
    columnNo,
    "\nerror: ",
    error
  );
};

export default function Invoice() {
  const [searchInvoiceTerm, setSearchInvoiceTerm] = useState("");
  const [searchDispatchTerm, setSearchDispatchTerm] = useState("");
  const [searchContainerTerm, setSearchContainerTerm] = useState("");
  const [searchCustomerTerm, setSearchCustomerTerm] = useState("");
  const [dispatches, setDispatches] = useState([]);
  const [alldispatches, setAllDispatches] = useState({
    all: [],
  });
  const [cheques, setCheques] = useState([]);
  const [ready, setReady] = useState(false);
  const [perms, setPerms] = useState(false);
  const [grandTotalDue, setGrandTotalDue] = useState(0);
  const [paidCheck, setPaidCheck] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const formattedGrandTotalDue = grandTotalDue ? (Math.round(grandTotalDue * 100) / 100).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  }) : '';
  let today = new Date();
  today =
    String(today.getMonth() + 1).padStart(2, "0") +
    "/" +
    String(today.getDate()).padStart(2, "0") +
    "/" +
    today.getFullYear();
  const invoiceTableColumns = [
    { field: "dispatchNumber", title: "Dispatch#", editable: "never" },
    { field: "invoiceNumber", title: "Invoice#", editable: "never" },
    { field: "containerNumber", title: "Container#", editable: "never" },
    { field: "bookingNumber", title: "Booking#", editable: "never" },
    { field: "total", title: "Total Cost", editable: "never", render: (rowData) => `$${rowData.total}` },
    { field: "totalp", title: "Total Paid", editable: "never", render: (rowData) => `$${rowData.totalp}` },
    { field: "remaining", title: "Remaining", editable: "never", render: (rowData) => `$${rowData.remaining}` },
    { field: "chequeNumber", title: "Cheque#" },
    { field: "chequeAmt", title: "Cheque AMT" },
  ];
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };
  const idleTimerRef = useRef(null);
  const idleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimerRef,
    timeout: 60 * 15 * 1000,
    onIdle: onIdle,
  });
  const [invoice, setInvoice] = useState({
    invoiceNumber: "",
    shipperNumber: "",
    pickupDate: "",
    billedDate: "",
    shipper: "",
    consignee: "",
    billedTo: "",
    remitTo: "LDT Transport Inc. 3154 SparrowDrive, Sacramento, CA, 95834",
    items: [
      {
        desc: "Container:",
        weight: 0,
        charges: "Rate",
        amount: 0,
      },
      {
        desc: "Booking Number",
        charges: "FSC",
        amount: 0,
      },
      {
        desc: "Chassis #:",
        charges: "Toll",
        amount: 0,
      },
      {
        charges: "Chassis Rental",
        amount: 0,
      },
      {
        charges: "Drop",
        amount: 0,
      },
      {
        charges: "Chassis Gate Fee",
        amount: 0,
      },
      {
        charges: "Standby",
        amount: 0,
      },
      {
        charges: "Port Congestion",
        amount: 0,
      },
      {
        charges: "Storage",
        amount: 0,
      },
      {
        charges: "Scale",
        amount: 0,
      },
      {
        charges: "Genset",
        amount: 0,
      },
      {
        charges: "Per Diem",
        amount: 0,
      },
      {
        charges: "Oversize",
        amount: 0,
      },
      {
        charges: "Split Chassis",
        amount: 0,
      },
      {
        charges: "Load Gate Fee",
        amount: 0,
      },
      {
        charges: "Pre Pull",
        amount: 0,
      },{
        charges: "Stop Off",
        amount: 0,
      },
      {
        charges: "Other",
        amount: 0,
      },
      {
        charges: "Total",
        amount: 0,
      },
    ],
  });
  const MyDocument = () => (
    <Document filename={`invoice-.pdf`}>
      <Page size="A4" style={styles.page}>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>LDT Transport Inc.</Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle2}>USDOT 1420414, MC 537273</Text>
        </View>
        <View>
          <Text style={styles.remitlabel4}>
            INVOICE NUMBER: {invoice.invoiceNumber}
          </Text>
          <Text style={styles.remitlabel}>
            SHIPPER NUMBER: {invoice.shipperNumber}
          </Text>
          <Text style={styles.remitlabel}>
            PICKUP DATE: {invoice.pickupDate}
          </Text>
          <Text style={styles.remitlabel4}>
            BILLED DATE: {invoice.billedDate}
          </Text>
        </View>
        <View>
          <Text style={styles.label}>SHIPPER: {invoice.shipper}</Text>
          <Text style={styles.label}>CONSIGNEE: {invoice.consignee}</Text>
          <Text style={styles.label}>BILLED TO: {invoice.billedTo}</Text>
        </View>
        <View style={styles.container}>
          <Text style={styles.description}>DESCRIPTION</Text>
          <Text style={styles.weight}>WEIGHT</Text>
          <Text style={styles.charges}>CHARGES</Text>
          <Text style={styles.amount}></Text>
        </View>
        {InvoiceTableRow(invoice)}
        <View style={styles.direction}>
          <Text style={styles.remitlabel}>REMIT TO: LDT Transport Inc.</Text>
        </View>
        <View style={styles.direction}>
          <Text style={styles.remitlabel}>
            3154 Sparrow Drive, Sacramento, CA, 95834
          </Text>
        </View>
        <View style={styles.direction}>
          <Text style={styles.remitlabel}>
            916-873-7626, ldt.trans@gmail.com
          </Text>
        </View>
      </Page>
    </Document>
  );
  const TableFooter = () => {
    return (
      <div>
        <p>Total Rows: {alldispatches.all.length}</p>
      </div>
    );
  };
  const InvoiceTableRow = ({ items }) => {
    const rows = items.map((item) => (
      <View style={styles.row}>
        <Text style={styles.description}>{item.desc}</Text>
        <Text style={styles.weight}>{item.weight}</Text>
        <Text style={styles.charges}>{item.charges}:</Text>
        <Text style={styles.amount}>${item.amount}</Text>
      </View>
    ));
    return <Fragment>{rows}</Fragment>;
  };
  axios.defaults.withCredentials = true;
  const formatDate = (date) => {
    if (date !== undefined && date !== null && date !== "") {
      const monthMap = new Map([
        ["Jan", "01"],
        ["Feb", "02"],
        ["Mar", "03"],
        ["Apr", "04"],
        ["May", "05"],
        ["Jun", "06"],
        ["Jul", "07"],
        ["Aug", "08"],
        ["Sep", "09"],
        ["Oct", "10"],
        ["Nov", "11"],
        ["Dec", "12"],
      ]);
      let splitDate = String(date).split(" ");
      let dateString = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[3];
      let monthAbbreviation = dateString.substr(0, 3);
      let modifiedDateString = dateString.replace(monthAbbreviation, monthMap.get(monthAbbreviation));
      return modifiedDateString;
    } else {
      return "";
    }
  };
  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    page2: {
      fontFamily: "Helvetica",
      fontSize: 11,
      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    titleContainer: {
      flexDirection: "row",
      marginTop: 12,
    },
    reportTitle: {
      color: "#61dafb",
      letterSpacing: 4,
      fontSize: 25,
      textAlign: "center",
      textTransform: "uppercase",
    },
    reportTitle2: {
      color: "#61dafb",
      letterSpacing: 2,
      fontSize: 18,
      textAlign: "center",
      textTransform: "uppercase",
    },
    remitlabel: {
      width: "100%",
    },
    remitlabel4: {
      width: "100%",
      color: "red",
    },
    label: {
      width: "100%",
      borderBottomColor: "#bff0fd",
      borderTopColor: "#bff0fd",
      borderLeftColor: "#bff0fd",
      borderRightColor: "#bff0fd",
      borderBottomWidth: 1,
      borderTopWidth: 1,
      borderLeftWidth: 1,
      borderRightWidth: 1,
    },
    direction: {
      flexDirection: "row",
    },
    container: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      backgroundColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      textAlign: "center",
      fontStyle: "bold",
    },
    description: {
      width: "50%",
      textAlign: "left",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      paddingLeft: 8,
    },
    weight: {
      width: "10%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "right",
      paddingRight: 8,
    },
    charges: {
      width: "25%",
      borderRightColor: "#90e5fc",
      borderRightWidth: 0,
      textAlign: "right",
      paddingRight: 8,
    },
    amount: {
      width: "15%",
      borderRightColor: "#90e5fc",
      textAlign: "left",
      paddingRight: 8,
    },
    row: {
      flexDirection: "row",
      borderBottomColor: "#bff0fd",
      borderBottomWidth: 1,
      alignItems: "center",
      height: 24,
      fontStyle: "bold",
    },
    footerTitleContainer: {
      flexDirection: "row",
      textAlign: "center",
      marginTop: 12,
    },
    footerReportTitle: {
      fontSize: 12,
      textAlign: "center",
      textTransform: "uppercase",
    },
  });
  const onIdle = () => {
    try {
      const location = window.location.href.split("/");
      axios
        .post(`${BASE_URL}/logout`, {
          location: location[3],
        })
        .then((response) => {
          window.location = "/";
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchByInvoice = () => {
    try {
      if (searchInvoiceTerm === "") {
        alert("please enter a search term");
      }
      axios
        .post(`${BASE_URL}/invoice/searchinvoice`, {
          search: searchInvoiceTerm,
          paidCheck: paidCheck,
        })
        .then((response) => {
          if(response.data.check === undefined) {
            alert(response.data.error);
          } else {
            //alert(response.data.check);
          
            const invoiceLists = {
              all: [],
            };
            if(response.data.check.length > 12){
              for (let x = 0; x < response.data.check.length; x += 11) {
                let invoiceOBJ = {
                  dispatchNumber: response.data.check[x],
                  bookingNumber: response.data.check[x + 1].trim().toUpperCase(),
                  container: response.data.check[x + 2].trim().toUpperCase(),
                  costs: response.data.check[x + 3].trim(),
                  totalDue: response.data.check[x + 4].trim(),
                  billedDate: formatDate(response.data.check[x + 5]),
                  Location: response.data.check[x + 6],
                  Driver: response.data.check[x + 7],
                  invoiceNumber: response.data.check[x + 8],
                  paid: response.data.check[x + 9],
                  billedDate: formatDate(response.data.check[x + 10]),
                  customer: response.data.check[x + 11].trim().toUpperCase()
                };
                setGrandTotalDue(invoiceOBJ.totalDue)
                if (invoiceOBJ.paid === "false") {
                  invoiceOBJ.paid = 0;
                }
                invoiceLists.all.push(invoiceOBJ);
              }
            } else {
              let invoiceOBJ = {
                dispatchNumber: response.data.check[0],
                bookingNumber: response.data.check[0 + 1].trim().toUpperCase(),
                container: response.data.check[0 + 2].trim().toUpperCase(),
                costs: response.data.check[0 + 3].trim(),
                totalDue: response.data.check[0 + 4].trim(),
                billedDate: formatDate(response.data.check[0 + 5]),
                Location: response.data.check[0 + 6],
                Driver: response.data.check[0 + 7],
                invoiceNumber: response.data.check[0 + 8],
                paid: response.data.check[0 + 9],
                billedDate: formatDate(response.data.check[0 + 10]),
                customer: response.data.check[0 + 11].trim().toUpperCase()
              };
              setGrandTotalDue(invoiceOBJ.totalDue)
              if (invoiceOBJ.paid === "false") {
                invoiceOBJ.paid = 0;
              }
              invoiceLists.all.push(invoiceOBJ);
            }
            setAllDispatches(invoiceLists);
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchByDispatch = () => {
    try {
      if (searchDispatchTerm === "") {
        alert("please enter a search term");
      }
      axios
        .post(`${BASE_URL}/invoice/searchDispatch`, {
          search: searchDispatchTerm,
          paidCheck: paidCheck,
        })
        .then((response) => {
          const invoiceLists = {
            all: [],
          };
          if(response.data.check.length > 12){
            for (let x = 0; x < response.data.check.length; x += 11) {
              let invoiceOBJ = {
                dispatchNumber: response.data.check[x],
                bookingNumber: response.data.check[x + 1].trim().toUpperCase(),
                container: response.data.check[x + 2].trim().toUpperCase(),
                costs: response.data.check[x + 3].trim(),
                totalDue: response.data.check[x + 4].trim(),
                billedDate: formatDate(response.data.check[x + 5]),
                Location: response.data.check[x + 6],
                Driver: response.data.check[x + 7],
                invoiceNumber: response.data.check[x + 8],
                paid: response.data.check[x + 9],
                billedDate: formatDate(response.data.check[x + 10]),
                customer: response.data.check[x + 11].trim().toUpperCase()
              };
              setGrandTotalDue(invoiceOBJ.totalDue)
              if (invoiceOBJ.paid === "false") {
                invoiceOBJ.paid = 0;
              }
              invoiceLists.all.push(invoiceOBJ);
            }
          } else {
            let x = 0
            let invoiceOBJ = {
              dispatchNumber: response.data.check[x],
              bookingNumber: response.data.check[x + 1].trim().toUpperCase(),
              container: response.data.check[x + 2].trim().toUpperCase(),
              costs: response.data.check[x + 3].trim(),
              totalDue: response.data.check[x + 4].trim(),
              billedDate: formatDate(response.data.check[x + 5]),
              Location: response.data.check[x + 6],
              Driver: response.data.check[x + 7],
              invoiceNumber: response.data.check[x + 8],
              paid: response.data.check[x + 9],
              billedDate: formatDate(response.data.check[x + 10]),
              customer: response.data.check[x + 11].trim().toUpperCase()
            };
            setGrandTotalDue(invoiceOBJ.totalDue)
            if (invoiceOBJ.paid === "false") {
              invoiceOBJ.paid = 0;
            }
            invoiceLists.all.push(invoiceOBJ);
          }
          
          setAllDispatches(invoiceLists);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchByContainer = () => {
    try {
      if (searchContainerTerm === "") {
        alert("please enter a search term");
      }
      axios
        .post(`${BASE_URL}/invoice/searchContainer`, {
          search: searchContainerTerm,
          paidCheck: paidCheck,
        })
        .then((response) => {
          const invoiceLists = {
            all: [],
          };

          for (let x = 0; x < response.data.check.length; x += 12) {
            let invoiceOBJ = {
              dispatchNumber: response.data.check[x],
              bookingNumber: response.data.check[x + 1].trim().toUpperCase(),
              container: response.data.check[x + 2].trim().toUpperCase(),
              costs: response.data.check[x + 3].trim(),
              totalDue: response.data.check[x + 4].trim(),
              billedDate: formatDate(response.data.check[x + 5]),
              Location: response.data.check[x + 6],
              Driver: response.data.check[x + 7],
              invoiceNumber: response.data.check[x + 8],
              paid: response.data.check[x + 9],
              billedDate: formatDate(response.data.check[x + 10]),
              customer: response.data.check[x + 11].trim().toUpperCase()
            };
            setGrandTotalDue(invoiceOBJ.totalDue)
            if (invoiceOBJ.paid === "false") {
              invoiceOBJ.paid = 0;
            }
            invoiceLists.all.push(invoiceOBJ);
          }
          setAllDispatches(invoiceLists);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const searchByCustomer = () => {
    try {
      if (searchCustomerTerm === "") {
        alert("please enter a search term");
      }
      axios
        .post(`${BASE_URL}/invoice/searchCustomer`, {
          search: searchCustomerTerm,
          paidCheck: paidCheck,
        })
        .then((response) => {
          if (response.data.check === "there are no dispatches") {
            alert("customer has no dispatches");
          } else {
            const invoiceLists = {
              all: [],
            };
            setGrandTotalDue(response.data.total[0])
            for (let x = 0; x < response.data.check.length; x += 12) {
              let invoiceOBJ = {
                dispatchNumber: response.data.check[x],
                bookingNumber: response.data.check[x + 1].trim().toUpperCase(),
                container: response.data.check[x + 2].trim().toUpperCase(),
                costs: response.data.check[x + 3].trim(),
                totalDue: response.data.check[x + 4].trim(),
                billedDate: formatDate(response.data.check[x + 5]),
                Location: response.data.check[x + 6],
                Driver: response.data.check[x + 7],
                invoiceNumber: response.data.check[x + 8],
                paid: response.data.check[x + 9],
                billedDate: formatDate(response.data.check[x + 10]),
                customer: response.data.check[x + 11].trim().toUpperCase()
              };
              if (invoiceOBJ.paid === "false") {
                invoiceOBJ.paid = 0;
              }
              invoiceLists.all.push(invoiceOBJ);
            }
            setAllDispatches(invoiceLists);
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const createCheque = (dispatchOBJ) => {
    try {
      axios
        .post(`${BASE_URL}/invoice/createCheque`, {
          uid: dispatchOBJ.uid,
          dispatchNum: dispatchOBJ.dispatchNumber,
          chequeNum: dispatchOBJ.chequeNumber,
          chequeAmmount: dispatchOBJ.chequeAmt,
          date: null,
        })
        .then((response) => {
          setUnPaid(dispatchOBJ);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const setPaid = (dispatchOBJ) => {
    try {
      axios
        .post(`${BASE_URL}/invoice/createCheque`, {
          uid: dispatchOBJ.uid,
          dispatchNum: dispatchOBJ.dispatchNumber,
          chequeNum: dispatchOBJ.chequeNumber,
          chequeAmmount: dispatchOBJ.chequeAmt,
          date: null,
        })
        .then((response) => {
          axios
            .post(`${BASE_URL}/invoice/setPaid`, {
              dispatchNum: dispatchOBJ.dispatchNumber,
            })
            .then((response) => {
              // Update the paid status in alldispatches state
              const updatedDispatches = alldispatches.all.map(dispatch => {
                if (dispatch.dispatchNumber === dispatchOBJ.dispatchNumber) {
                  return {
                    ...dispatch,
                    paid: 1
                  };
                }
                return dispatch;
              });

              // Update alldispatches state
              setAllDispatches({
                ...alldispatches,
                all: updatedDispatches
              });
            });
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const setUnPaid = (dispatchOBJ) => {
    try {
      axios
        .post(`${BASE_URL}/invoice/setUnPaid`, {
          dispatchNum: dispatchOBJ.dispatchNumber,
        })
        .then((response) => {
          let newDispatches = alldispatches;
          newDispatches.all[0].paid = 0;
          setAllDispatches(newDispatches);
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  const getCheques = (dispNumber) => {
    try {
      setReady(true);
      axios
        .post(`${BASE_URL}/invoice/getCheques`, {
          dispatchNum: dispNumber.dispatchNumber,
        })
        .then((response) => {
          let allCheques = [];
          let totalpaid = 0;
          if (!Array.isArray(response.data.check)) {
            let chequeOBJ = {
              uid: 0,
              dispatchNumber: dispNumber.dispatchNumber,
              bookingNumber: dispNumber.bookingNumber,
              containerNumber: dispNumber.containerNumber,
              total: dispNumber.totalDue,
              totalp: 0,
              remaining: dispNumber.totalDue,
              chequeNumber: "",
              chequeAmt: 0,
              invoiceNumber: dispNumber.invoiceNumber
            };
            allCheques.push(chequeOBJ);
            let stateCheques = [...cheques];
            stateCheques = allCheques;
            setCheques(stateCheques);
          } else {
            for (let x = 0; x < response.data.check.length; x += 8) {
              totalpaid += Number(response.data.check[x + 3]);
              let chequeOBJ = {
                uid: response.data.check[x],
                dispatchNumber: response.data.check[x + 1],
                chequeNumber: response.data.check[x + 2],
                chequeAmt: response.data.check[x + 3],
                containerNumber: response.data.check[x + 5],
                bookingNumber: response.data.check[x + 6],
                total: response.data.check[x + 7],
                totalp: totalpaid,
                remaining: response.data.check[x + 7] - totalpaid,
                invoiceNumber: response.data.check[x + 4]
              };
              allCheques.push(chequeOBJ);
            }
            if (allCheques[allCheques.length - 1].remaining > 0) {
              let chequeOBJ = {
                uid: 0,
                dispatchNumber:
                  allCheques[allCheques.length - 1].dispatchNumber,
                bookingNumber: allCheques[allCheques.length - 1].bookingNumber,
                containerNumber:
                  allCheques[allCheques.length - 1].containerNumber,
                total: allCheques[allCheques.length - 1].total,
                totalp: allCheques[allCheques.length - 1].totalp,
                remaining: allCheques[allCheques.length - 1].remaining,
                chequeNumber: "",
                chequeAmt: 0,
                invoiceNumber:
                  allCheques[allCheques.length - 1].invoiceNumber,
              };
              allCheques.push(chequeOBJ);
            }
            let stateCheques = [...cheques];
            stateCheques = allCheques;
            setCheques(stateCheques);
          }
          axios
            .post(`${BASE_URL}/invoice/getInvoiceInfo`, {
              dispatchNum: dispNumber.dispatchNumber,
            })
            .then((response) => {
              let pickupDate = response.data.check[2].split(" ");
              let pickupDatemonth =
                "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(pickupDate[1]) /
                  3 +
                1;
              let billedDate = response.data.check[3].split(" ");
              let billedDatemonth =
                "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(billedDate[1]) /
                  3 +
                1;
              let grandtotal =
                Number(response.data.check[10]) +
                Number(response.data.check[11]) +
                Number(response.data.check[12]) +
                Number(response.data.check[13]) +
                Number(response.data.check[14]) +
                Number(response.data.check[15]) +
                Number(response.data.check[16]) +
                Number(response.data.check[17]) +
                Number(response.data.check[18]) +
                Number(response.data.check[19]) +
                Number(response.data.check[20]) +
                Number(response.data.check[21]) +
                Number(response.data.check[22]) +
                Number(response.data.check[23]) +
                Number(response.data.check[25]) +
                Number(response.data.check[26]) + 
                Number(response.data.check[28]) +
                Number(response.data.check[29]);
                console.log(grandtotal)
              let invoiceNumber = response.data.check[0];
              if (invoiceNumber === "") {
                invoiceNumber = response.data.check[27];
              }
              setInvoice({
                invoiceNumber: invoiceNumber,
                shipperNumber: response.data.check[1],
                pickupDate: `${pickupDatemonth}/${pickupDate[2]}/${pickupDate[3]}`,
                billedDate: `${billedDatemonth}/${billedDate[2]}/${billedDate[3]}`,
                shipper: response.data.check[4].trim(),
                consignee: response.data.check[5].trim(),
                billedTo: response.data.check[6].trim(),
                remitTo:
                  "LDT Transport Inc. 3154 SparrowDrive, Sacramento, CA, 95834",
                items: [
                  {
                    desc: `Container: ${response.data.check[7]}`,
                    weight: response.data.check[9],
                    charges: "Rate",
                    amount: response.data.check[10],
                  },
                  {
                    desc: `Booking Number: ${response.data.check[8]}`,
                    charges: "FSC",
                    amount: response.data.check[11],
                  },
                  {
                    desc: `Chassis #: ${response.data.check[30]}`,
                    charges: "Tolls",
                    amount: response.data.check[12],
                  },
                  {
                    charges: "Chassis Rental",
                    amount: response.data.check[13],
                  },
                  {
                    charges: "Drop",
                    amount: response.data.check[14],
                  },
                  {
                    charges: "Chassis Gate Fee",
                    amount: response.data.check[15],
                  },
                  {
                    charges: "Standby",
                    amount: response.data.check[16],
                  },
                  {
                    charges: "Port Congestion",
                    amount: response.data.check[26],
                  },
                  {
                    charges: "Storage",
                    amount: response.data.check[17],
                  },
                  {
                    charges: "Scale",
                    amount: response.data.check[18],
                  },
                  {
                    charges: "Genset",
                    amount: response.data.check[19],
                  },
                  {
                    charges: "Per Diem",
                    amount: response.data.check[20],
                  },
                  {
                    charges: "Oversize",
                    amount: response.data.check[21],
                  },
                  {
                    charges: "Split Chassis",
                    amount: response.data.check[22],
                  },
                  {
                    charges: "Load Gate Fee",
                    amount: response.data.check[23],
                  },
                  {
                    charges: "Pre Pull",
                    amount: response.data.check[29],
                  },
                  {
                    charges: "Stop Off",
                    amount: response.data.check[28],
                  },
                  {
                    charges: "Other",
                    amount: response.data.check[25],
                  },
                  {
                    charges: "Total",
                    amount: grandtotal,
                  },
                ],
              });
            });
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  useEffect(() => {
    try {
      axios
        .get(`${BASE_URL}/api/login`)
        .then((response) => {
          if (response.data.loggedIn === true) {
            if (
              response.data.permissions.admin === "true" ||
              response.data.permissions.admin === "1"
            ) {
              setPerms(true);
            }
            if (
              response.data.permissions.remittance === "true" ||
              response.data.permissions.remittance === "1"
            ) {
              setPerms(true);
            }
          } else {
            window.location = "/";
          }
        });
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, []);
  useEffect(() => {
    try {
      console.dir(alldispatches.all)
    } catch (err) {
      console.log(err);
      alert(err);
    }
  }, [alldispatches]);
  const theme = createMuiTheme({
    palette: {
      type: "light",
    },
  });
  const chequemagagment = (selectedRow) => {
    let dispatchOBJ = {
      dispatchNumber: selectedRow.dispatchNumber,
      bookingNumber: selectedRow.bookingNumber,
      containerNumber: selectedRow.container,
      cost: selectedRow.costs,
      total: selectedRow.totalDue,
      totalp: 0,
      chequeNumber: selectedRow.chequeNumber,
      chequeAmt: selectedRow.chequeAMT,
    };
    let newDispatches = [...dispatches];
    newDispatches = [];
    newDispatches.push(dispatchOBJ);
    setDispatches(newDispatches);
  };
  const handleDeleteRows = (event, rowData) => {
    try {
      axios
        .post(`${BASE_URL}/invoice/removeCheque`, {
          uid: rowData[0].uid,
          dispatchNum: rowData[0].dispatchNumber,
          chequeNum: rowData[0].chequeNumber,
          chequeAmmount: rowData[0].chequeAmt,
          date: today,
        })
        .then((response) => {
          setUnPaid(rowData[0]);
        });
      let newCheques = [...cheques];
      newCheques.splice(rowData[0].tableData.id, 1);
      setCheques(newCheques);
      //getCheques(rowData[0]);
    } catch (err) {
      console.log(err);
      alert(err);
    }
  };
  function setDownloadAttributeForAllLinks(selectedRow) {
    const links = document.querySelectorAll("a");
    links.forEach(link => {
      link.setAttribute("download", `${selectedRow.invoiceNumber}`);
    });
  }
  switch (perms) {
    case true:
      return (
        <ThemeProvider theme={theme}>
          <div idleTimer={idleTimer}>
            <CssBaseline />
            <Grid container direction="row">
              <Grid item xs={3}>
                <MaterialTable
                  icons={tableIcons}
                  title={
                    <>
                      <div>
                        <p>Dispatches:{alldispatches.all.length}</p>
                      </div>
                    </>
                  }
                  // title="All Dispatches In Invoice"
                  data={alldispatches.all}
                  columns={[
                    {
                      field: "invoiceNumber",
                      title: "Invo#",
                      width: "5%",
                      cellStyle: { cellWidth: "5%" },
                      editable: "never",
                    },
                    {
                      field: "container",
                      title: "Cont#",
                      width: "40%",
                      cellStyle: { cellWidth: "40%" },
                      editable: "never",
                    },
                    {
                      field: "customer",
                      title: "Cust",
                      width: "5%",
                      cellStyle: { cellWidth: "5%" },
                      editable: "never",
                    },
                    {
                      field: "billedDate",
                      title: "BillDate",
                      width: "40%",
                      cellStyle: { cellWidth: "40%" },
                      editable: "never",
                    },
                    {
                      field: "paid",
                      title: "Paid",
                      width: "5%",
                      cellStyle: { cellWidth: "5%" },
                      type: "boolean",
                      editable: "never",
                    },
                  ]}
                  options={{
                    paging: false,
                    cellStyle: { borderRight: "1px solid " },
                    headerStyle: {
                      backgroundColor: "#DEF3FA",
                      color: "Black",
                      borderRight: "1px solid ",
                    },
                    maxBodyHeight: 700,
                    tableLayout: "auto",
                    exportButton: true,
                    rowStyle: rowData => ({
                      backgroundColor: rowData === selectedRow ? "#ccc" : "inherit",
                    }),
                    showFooter: true
                  }}
                  onRowClick={(evt, selectedRow) => {
                    setSelectedRow(selectedRow);
                    // document.querySelector("#root > div > header > div:nth-child(4) > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-grid-xs-9.css-jf2xl9 > div:nth-child(5) > a")
                    // const element = document.querySelector("#root > div > header > div:nth-child(6) > div > div.MuiGrid-root.MuiGrid-container.MuiGrid-grid-xs-9.css-jf2xl9 > div:nth-child(5) > a");
                    // console.log(element)
                    // // Check if the element exists
                    // if (element) {
                    //   // Set a new value for the "download" attribute
                    //   element.setAttribute("download", `${selectedRow.invoiceNumber}`);
                    // }
                    setDownloadAttributeForAllLinks(selectedRow);
                    console.dir(selectedRow)
                    if (selectedRow.Location === "") {
                      alert("dispatch still in progress");
                    } else if (selectedRow.billedDate === "") {
                      alert("dispatch has not been billed");
                      getCheques(selectedRow);
                      
                      chequemagagment(selectedRow);
                    } else if (
                      selectedRow.paid !== "false" &&
                      selectedRow.paid !== 0
                    ) {
                      alert("dispatch has been fully paid");
                      getCheques(selectedRow);
                      chequemagagment(selectedRow);
                    } else {
                      getCheques(selectedRow);
                      chequemagagment(selectedRow);
                    }
                  }}
                  components={{
                    Footer: props => <TableFooter />,
                  }}
                />
              </Grid>
              <Grid container xs={9} direction="row">
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="searchTermInvoice"
                    label="Search By Invoice"
                    name="search"
                    margin="normal"
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchInvoiceTerm(e.target.value);
                    }}
                  ></TextField>
                  <Button
                    fullWidth
                    id="searchInvoice"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={searchByInvoice}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="searchTermDispatch"
                    label="Search By Dispatch"
                    name="search"
                    margin="normal"
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchDispatchTerm(e.target.value);
                    }}
                  ></TextField>
                  <Button
                    fullWidth
                    id="searchDispatch"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={searchByDispatch}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="searchTermCustomer"
                    label="Search By Customer"
                    name="search"
                    margin="normal"
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchCustomerTerm(e.target.value);
                    }}
                  ></TextField>
                  <Button
                    fullWidth
                    id="searchCustomer"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={searchByCustomer}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="searchTermContainer"
                    label="Search By Container"
                    name="search"
                    margin="normal"
                    autoComplete="off"
                    onChange={(e) => {
                      setSearchContainerTerm(e.target.value);
                    }}
                  ></TextField>
                  <Button
                    fullWidth
                    id="searchContainer"
                    variant="contained"
                    color="primary"
                    margin="normal"
                    onClick={searchByContainer}
                  >
                    Search
                  </Button>
                </Grid>
                <Grid item xs={2}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="TotalProfit"
                  label="Total Profit"
                  name="TotalProfit"
                  margin="normal"
                  value={`${formattedGrandTotalDue}`}
                  // style={{ marginRight: 5, marginTop: 15 }}
                  autoComplete="off"
                  inputProps={{ readOnly: true }}
                ></TextField>
                  {/* <PDFDownloadLink
                    document={<Document>...</Document>}
                    filename={`invoice-${invoice.invoiceNumber}.pdf`} // Ensure that invoice.invoiceNumber is defined and has the correct value.
                  >
                    {({ loading }) => {
                      console.log("Filename:", `invoice-${invoice.invoiceNumber}.pdf`);
                      return loading ? "Loading Document..." : "Download";
                    }}
                  </PDFDownloadLink> */}
                  <PDFDownloadLink
                    download={`invoice-${invoice.invoiceNumber}.pdf`}
                    document={<MyDocument />}
                    filename={`invoice-${invoice.invoiceNumber}.pdf`}
                  >
                    {({ loading }) =>
                      loading ? (
                        <button>Loading Document...</button>
                      ) : (
                        <a
                          download={`invoice-${invoice.invoiceNumber}.pdf`}
                        >Download</a>
                      )
                    }
                  </PDFDownloadLink>
                </Grid>
                <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={paidCheck}
                        onChange={(e) => {
                          setPaidCheck(e.target.checked);
                        }}
                      />
                    }
                    label="Include Paid"
                  />
                </Grid>
                <Grid item xs={12} sm={20}>
                  <MaterialTable
                    icons={tableIcons}
                    title="Cheques"
                    data={cheques}
                    columns={invoiceTableColumns}
                    editable={{
                      onRowUpdate: (newRow, oldRow) =>
                        new Promise((resolve, reject) => {
                          if (
                            newRow.chequeAmt < 1 ||
                            newRow.chequeNumber === ""
                          ) {
                            alert(
                              "Cheque number and Cheque ammount can not be empty"
                            );
                          } else {
                            const newInvoice = [...cheques];
                            newInvoice[oldRow.tableData.id] = newRow;
                            newInvoice[oldRow.tableData.id].totalp =
                              Number(oldRow.totalp) + Number(newRow.chequeAmt);
                            newInvoice[oldRow.tableData.id].remaining =
                              Number(newRow.total) - Number(newRow.totalp);
                            if (newInvoice[oldRow.tableData.id].remaining < 0) {
                              alert(
                                "The cheque ammount entered is higher than the remaining balance.\nPlease Adjust"
                              );
                            }
                            if (
                              newInvoice[oldRow.tableData.id].remaining === 0
                            ) {
                              let chequeOBJ = {
                                uid: newRow.uid,
                                dispatchNumber: newRow.dispatchNumber,
                                bookingNumber: newRow.bookingNumber,
                                containerNumber: newRow.containerNumber,
                                cost: newRow.cost,
                                total: newRow.total,
                                totalp: newRow.totalp,
                                remaining: newRow.remaining,
                                chequeNumber: newRow.chequeNumber,
                                chequeAmt: newRow.chequeAmt,
                              };
                              // createCheque(chequeOBJ);
                              alert("fully paid");
                              setPaid(newInvoice[oldRow.tableData.id]);
                            }
                            if (
                              newRow.chequeAmt > 0 &&
                              newRow.chequeNumber.length > 0 &&
                              newInvoice[oldRow.tableData.id].remaining > 0
                            ) {
                              let chequeOBJ = {
                                uid: newRow.uid,
                                dispatchNumber: newRow.dispatchNumber,
                                bookingNumber: newRow.bookingNumber,
                                containerNumber: newRow.containerNumber,
                                cost: newRow.cost,
                                total: newRow.total,
                                totalp: newRow.totalp,
                                remaining: newRow.remaining,
                                chequeNumber: newRow.chequeNumber,
                                chequeAmt: newRow.chequeAmt,
                              };
                              let dispatchOBJ = {
                                uid: 0,
                                dispatchNumber: newRow.dispatchNumber,
                                bookingNumber: newRow.bookingNumber,
                                containerNumber: newRow.containerNumber,
                                cost: newRow.cost,
                                total: newRow.total,
                                totalp: newRow.totalp,
                                remaining: newRow.remaining,
                                chequeNumber: "",
                                chequeAmt: 0,
                              };
                              createCheque(chequeOBJ);
                              if (
                                newInvoice[Number(newInvoice.length - 1)]
                                  .chequeAmt !== 0
                              ) {
                                newInvoice.push(dispatchOBJ);
                              }
                            }
                            let totalpaid = 0;
                            let totalremaining = 1;
                            for (let x = 0; x < newInvoice.length; x++) {
                              totalpaid =
                                Number(totalpaid) +
                                Number(newInvoice[x].chequeAmt);
                              newInvoice[x].totalp = totalpaid;
                              newInvoice[x].remaining =
                                Number(newInvoice[x].total) - totalpaid;
                              totalremaining = newInvoice[x].remaining;
                            }
                            setCheques(newInvoice);
                            if (totalremaining > 0) {
                              //setUnPaid(newInvoice[oldRow.tableData.id]);
                            }
                          }
                          setTimeout(() => resolve(), 1000);
                        }),
                    }}
                    options={{
                      selection: true,
                      paging: false,
                      cellStyle: { borderRight: "1px solid " },
                      headerStyle: {
                        backgroundColor: "#DEF3FA",
                        color: "Black",
                        borderRight: "1px solid ",
                      },
                      maxBodyHeight: 700,
                    }}
                    actions={[
                      {
                        icon: () => <Delete />,
                        tooltip: "Delete Rows",
                        onClick: handleDeleteRows,
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      );
    case false:
      return "you do not have the correct permissions";
    default:
      return "you do not have permissions";
  }
}
